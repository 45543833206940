import { computed } from '@vue/composition-api'
import { footerModel } from '../../types/pages'
import HOST from '@/common/host'
import router from '@/router'

export default function useFooter() {
  const pcBg =
    'https://zyb-zhike-file.cdnjtzy.com/mis/762c4595-63ef-4ba2-b837-98b009da59b9.png'
  const about: footerModel = [
    {
      title: '关于云思智学',
      list: [
        { key: 'intro', value: '公司介绍', link: '/introduct' },
        { key: 'news', value: '公司新闻', link: '/news?type=news' },
        { key: 'report', value: '媒体报道', link: '/news?type=media' }
      ]
    },
    {
      title: '核心产品和服务',
      list: [
        { key: 'homework', value: '高质量作业', link: '/zhzy' },
        { key: 'classroom', value: '智慧课堂', link: '/zhkt' },
        { key: 'classafter', value: '云思课后服务', link: '/khfw' },
        { key: 'sports', value: '智慧体育', link: '/zhty' }
      ]
    },
    {
      title: '联系方式',
      list: [
        { key: 'phone', value: '客服：400-685-0500' },
        { key: 'email', value: '邮箱：qiaoyang02@zuoyebang.com' }
      ]
    }
  ]
  const sliceAbout = computed(() => about.slice(0, -1))

  const handleRedirect = (path: string) => {
    if (!path) return
    router.push({
      path
    })
  }

  // 官网名称与版权跟随域名变化
  let copyright = '',
    footerName = ''

  for (const item in HOST) {
    if (HOST[item].regex.test(location.hostname)) {
      footerName = HOST[item].name
      copyright = HOST[item].copyright
    }
  }

  return { pcBg, about, sliceAbout, handleRedirect, copyright, footerName }
}
